// Here you can add other styles

/* sidebar */
ul.c-sidebar-nav li{    
    &.c-sidebar-nav-title {
        padding-top: 0.5rem; padding-bottom: 0.5rem;
    }
    a {
        padding-top: 0.5rem; padding-bottom: 0.5rem;
    }
}

/* main */
.c-main {padding-top: 1rem;}

/* search-box */
.search-box {
	background-color: #fff;
	border: 1px solid rgb(216, 219, 224);
    border-radius: 0.25rem;
    padding: .75rem .3rem;

    .display-block {
        display: block;
    }

    .form-group.input-group {
        margin-top: 0.4rem;
        margin-bottom: 0.4rem;
    }

    .input-group-text {
        background-color: #fff;
        border: 0px;
        padding: 0px 0.5rem;
        font-size: 0.75rem;
        min-width: 60px;
    }

    .form-check-label {
        padding-right: 1rem;
        font-size: 0.75rem;
    }

    .row {
        margin-left: -10px;
        margin-right: -10px;
    }

    div[class*="col-"] {
        padding-left: 10px;
        padding-right: 10px;
    }

    button {
        font-size: .9rem;
    }

    span.required:before {
        content: "*";
        margin-right: 4px;
        color: red;
    }
}

/* extra-box */
.extra-box {
    padding: 0.5rem 0px;

    button {
        font-size: .9rem;
        background-color: white;
    }
}

/* body-box */
.body-box {
    background-color: #fff;
    border: 1px solid rgb(216, 219, 224);
    border-radius: 0.25rem;
    padding: .75rem .3rem;
    
    .box-title {
        padding-top: .7rem;
        button {
            padding: .15rem .4rem;
        }
        .form-group.input-group {
            margin-top: 0;
        }
    }
    .form-group.input-group {
        margin-top: .4rem;
        margin-bottom: .4rem;
        span.required:before {
            content: "*";
            margin-right: 4px;
            color: red;
        }
        input.form-control {
            @extend .form-control-sm;
        }
    }
    .input-group-text, input[type=file] {
        background-color: #fff;
        border: 0px;
        padding: 0px 0.5rem;
        font-size: 0.75rem;
        min-width: 50px;
    }
    .input-group .form-check-label {
        background-color: #fff;
        border: 0px;
        padding: 0px 0.5rem;
        font-size: 0.75rem;
        min-width: 50px;
    }
    .list-table, .list-paging {
        overflow-x: auto;
        margin-top: 10px;
        th, td {
            white-space: nowrap;
            vertical-align: middle;
            text-align: center;
        }
        th {
            min-width: 50px;
            padding: .3rem .5rem;
        }
        td {
            min-width: 50px;
            padding: .2rem .5rem;
        }
    }

    .table {
        thead tr {
            text-align: center;
        }
    }
}

.modal .body-box {
    border: none;
    padding: 0px;
}

.modal .modal-footer {
    display: block;
    text-align: center;
    button {
        @extend .btn-sm
    }
}

.header-box {
    
    display: block !important;

    .display-block {
        display: block;
    }

    .form-group.input-group {
        margin-top: 0.4rem;
        margin-bottom: 0.4rem;
    }

    .input-group-text {
        background-color: #fff;
        border: 0px;
        padding: 0px 0.5rem;
        font-size: 0.75rem;
        min-width: 60px;
    }

    .form-check-label {
        padding-right: 1rem;
        font-size: 0.75rem;
    }

    .row {
        margin-left: -10px;
        margin-right: -10px;
    }

    div[class*="col-"] {
        padding-left: 10px;
        padding-right: 10px;
    }

    button {
        font-size: .9rem;
    }
}

.custom-input {
    background-color: #fff;
    border: 1px solid #e4e7ea;
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.8rem !important;
    line-height: 1.5;
    margin: 0;
    color: #5c6873;
}

.custom-input-call {
    background-color: #fff;
    border: 1px solid #e4e7ea;
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.8rem !important;
    line-height: 1.5;
    margin: 0;
    color: #5c6873;
    width: 300%;
    text-align: left;
}

.custom-input-main {
    //content: url(../images/btn_date_more.png);
    background-color: #fff;
    border: 1.5px solid #c2c2c2;
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.8rem !important;
    line-height: 1.5;
    margin: 0;
    color: #5c6873;
    text-align: left;
}

.table-responsive table {
    white-space:nowrap;
}

.body-box table {
    overflow-x: auto;
}

.DateInput_input {
    padding : 4px  4px  4px 10px !important;
    font-size: 14px !important;
}

.textLeft {
    text-align : left;
}

.textCntr {
    text-align : center;
}

.react-time-picker__wrapper {
    border: 1px solid #e4e7ea !important;
    height: calc(1.5em + 0.5rem + 2px);
    
    .react-time-picker__inputGroup__amPm {
        color: #5c6873;
    }
    .react-time-picker__inputGroup__hour {
        color: #5c6873;
    }
    .react-time-picker__inputGroup__divider {
        color: #5c6873;
    }
    .react-time-picker__inputGroup__minute {
        color: #5c6873;
    }
}

.btnAlign {
    display: inline-block;

    .btn {
        margin: 0 1px !important;
    }

    .btn2 {
        min-width: 45px;
    }

    span {
        div {
            min-width: 45px;
        }
        button {
            min-width: 45px;
        }
    }
}



.bdBox {
    .body-box {
        width: 50%;
        display: inline-flex;
    }
    .body-box:first-child {
        margin-right: 45px;
    }
    p {
        margin-bottom: 0px;
    }
    tr td:first-child {
        text-align: center;
        vertical-align: middle;
    }
    span {
        font-weight: bold;
    }
}


.red {
    color: red
}
.blue {
    color: blue
}
.orange {
    color: orange
}
.green {
    color: green
}
.info {
    color: #39f
}

.form-check {
    input[type=checkbox] {
        margin-left: -0.7rem !important
    }
    input[type=radio] {
        margin-left: -0.7rem !important
    }
    .chk-label {
        margin-left: 0.5rem 
    }
}

.inlinebk {
    display: inline-block;
    
    button {
        float: right;
    }
}

.rfTb tr {
    height: 38px;
    .line {
        display: block;
        padding-bottom: 4px;
        margin-bottom: 3px;
        border-bottom: 1px solid #d8dbe0;
    }
    
    hr.underline {
        margin: 4px -.3rem;
    }

    td {
        vertical-align: middle;
        text-align: center;

        div.split-row {
            height: 30px;
            display: inline-flex;
            align-items: center;
        }
    }

    td.textLeft {
        text-align : left;
    }

    .leftline {
        border-left: solid 1px;
        border-color: lightgray;
    }
}

.center tr td {
        vertical-align: middle;
}

.file tr {
    background-color: white !important;
}

.file tr {
    background-color: white !important;
}
.svc {
    border: 1px solid #e4e7ea;
    border-radius: 5px;
}

html:not([dir=rtl]) .form-check {
    padding-left: 1rem;
}
// 데이터 테이블 스크롤 기능에 따른 css 추가
.st_bg_green {
    background-color: lime;
}

.st_bg_red {
    background-color: red;
    color: white;
}


.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.col-main-state {
    padding-left: 7px;
    padding-right: 7px;

    .card-body .h4 {
        font-size: 2rem;
    }

    .progress {
        height: 6px;
        margin-bottom: .2rem !important
    }
}

.taks-list svg{
    margin-top: -3px;
    margin-left: 5px;
}